<template>
  <v-form>
    <v-container fluid>
      <v-row>
      <v-col offset-md="3" cols="1" sm="1" md="1">
          <v-checkbox
            v-model="item.publie"
            :error-messages="publieErrors"
            :label="$t('Publié')"
            @input="$v.item.publie.$touch()"
            @blur="$v.item.publie.$touch()"
          />
        </v-col>
      <v-col cols="5" sm="5" md="5">
          <v-text-field
            v-model="item.nom"
            :error-messages="nomErrors"
            :label="$t('Nom')"
            @input="$v.item.nom.$touch()"
            @blur="$v.item.nom.$touch()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-select
            v-model="item.categorie"
            :items="categoriePartenaireSelectItems"
            :error-messages="categoriePartenaireErrors"
            :no-data-text="$t('No results')"
            :label="$t('Catégorie')"
            item-text="nom"
            item-value="@id"
          />
        </v-col>
      </v-row>
      <v-row>
      <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-select
            v-model="item.evenement"
            :items="evenementsSelectItems"
            multiple
            chips
            :error-messages="evenementsErrors"
            :no-data-text="$t('No results')"
            :label="$t('Evenement')"
            item-text="nom"
            item-value="@id"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <media-object v-model="item.mediaObjectImage" is-image max-height="25vh"/>
        </v-col>
      </v-row>    
      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.siteWeb"
            :error-messages="siteWebErrors"
            :label="$t('Site Web')"
            @input="$v.item.siteWeb.$touch()"
            @blur="$v.item.siteWeb.$touch()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.facebook"
            :error-messages="facebookErrors"
            :label="$t('Facebook')"
            @input="$v.item.facebook.$touch()"
            @blur="$v.item.facebook.$touch()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <wysiwyg
            v-model="item.description"
            :error-messages="descriptionErrors"
            :label="$t('Description')"
            @input="$v.item.description.$touch()"
            @blur="$v.item.description.$touch()"
          />
        </v-col>
      </v-row>
      
      
      
    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import MediaObject from "../MediaObject";

export default {
  name: 'PartenaireForm',
  components: {MediaObject},
  mixins: [validationMixin],
  props: {
    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
      this.categoriePartenaireGetSelectItems();
      this.evenementsGetSelectItems();
  },
  data() {
    return {
        fileImage: null,
        nom: null,
        siteWeb: null,
        facebook: null,
        image: null,
        description: null,
        categoriePartenaires: null,
        publie: null,
        evenement: null,
    };
  },
  computed: {
      ...mapFields('categoriePartenaire', {
        categoriePartenaireSelectItems: 'selectItems'
      }),
      ...mapFields("evenement", {
      evenementsSelectItems: "selectItems", 
    }),

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    fileImageErrors() {
      const errors = [];

      if (!this.$v.item.fileImage.$dirty) return errors;

      has(this.violations, 'fileImage') && errors.push(this.violations.fileImage);


      return errors;
    },
    nomErrors() {
      const errors = [];

      if (!this.$v.item.nom.$dirty) return errors;

      has(this.violations, 'nom') && errors.push(this.violations.nom);


      return errors;
    },
    siteWebErrors() {
      const errors = [];

      if (!this.$v.item.siteWeb.$dirty) return errors;

      has(this.violations, 'siteWeb') && errors.push(this.violations.siteWeb);


      return errors;
    },
    facebookErrors() {
      const errors = [];

      if (!this.$v.item.facebook.$dirty) return errors;

      has(this.violations, 'facebook') && errors.push(this.violations.facebook);


      return errors;
    },
    imageErrors() {
      const errors = [];

      if (!this.$v.item.image.$dirty) return errors;

      has(this.violations, 'image') && errors.push(this.violations.image);


      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.item.description.$dirty) return errors;

      has(this.violations, 'description') && errors.push(this.violations.description);


      return errors;
    },
    categoriePartenaireErrors() {
      const errors = [];

      if (!this.$v.item.categoriePartenaire.$dirty) return errors;

      has(this.violations, 'categoriePartenaire') && errors.push(this.violations.categorie);


      return errors;
    },
    evenementsErrors() {
      const errors = [];

      if (!this.$v.item.evenements.$dirty) return errors;

      has(this.violations, "evenements") &&
        errors.push(this.violations.evenements);

      return errors;
    },
    publieErrors() {
      const errors = [];

      if (!this.$v.item.publie.$dirty) return errors;

      has(this.violations, 'publie') && errors.push(this.violations.publie);


      return errors;
    },

    violations() {
      return this.errors || {};
    }
  },
  methods: {
      ...mapActions({
        categoriePartenaireGetSelectItems: 'categoriePartenaire/fetchSelectItems',
        evenementsGetSelectItems: "evenement/fetchSelectItems",
      }),
  },
  validations: {
    item: {
      fileImage: {
      },
      nom: {
      },
      siteWeb: {
      },
      facebook: {
      },
      image: {
      },
      description: {
      },
      evenements: {},
      categoriePartenaire: {
      },
      publie: {
      },
    }
  }
};
</script>
